/*
site/site.scss
*/

@import "../global";
@import "../library/css3";
@import "../library/grid";
@import "../library/table";

//
// Google Fonts
//

@import url(http://fonts.googleapis.com/css?family=Roboto:400,300,100);

//
// Container
//

.container {
	margin: 0 auto;

    @include page-width(4);

	@include breakpoint(small) {
		@include page-width(6);
	}

	@include breakpoint(medium) {
		@include page-width(8);
	}

	@include breakpoint(large) {
		@include page-width(12);
	}

	@include breakpoint(extra-large) {
		@include page-width(16);
	}
}

//
// Logo
//

.logo {
    display: block;
    height: 200px;
    width: 200px;
    margin: 0 auto $line-height * 1em auto;
    background-color: $secondary-color;
    background-image: url(/Images/Logo/flamebug-logo-white.svg);
    background-repeat: no-repeat;
    background-position: center 40%;
    background-size: 75%;
    border-radius: 50%;
}

//
// Content Header
//

#content > header {
	position: relative;
	padding: $line-height * 2em 0;
	padding-bottom: $line-height * 1em;
	background-color: $primary-color;
	color: #fff;
	text-align: center;

	&:after {
		content: "";
		position: absolute;
		left: 50%;
		bottom: $line-height * -2em;
		margin-left: $line-height * -1em;
		border-width: $line-height * 1em;
		border-style: solid;
		border-color: transparent;
		border-top-color: $primary-color;
	}

	h1 {
		line-height: 1.1;
		letter-spacing: -2px;
		color: inherit;
	}

	h1 small {
		display: block;
		font-size: .5em;
		letter-spacing: 0;
		opacity: .5;
	}

    p, h1 {
        @include max-column;
    }

	a {
		color: #fff;
		text-decoration: underline;

		&:hover {
			color: rgba(255,255,255,0.6);
		}
	}
}

//
// Content Section
//

#content section {
	padding: $line-height * 1em 0;
	text-align: center;
}

//
// Footer Section
//

#footer {

    .social {
      display: inline-block;
      height: 2em;
      width: 2em;
      text-indent: -9999px;
      background-repeat: no-repeat;
      background-size: cover;
      opacity: .5;

      & + .social {
          margin-left: .25em;
      }
    }

    .twitter {
      background-image: url("/Images/Social/twitter-circle.svg");
    }

    .facebook {
      background-image: url("/Images/Social/facebook-circle.svg");
    }

    .linkedin {
      background-image: url("/Images/Social/linkedin-circle.svg");
    }
}

//
// Button
//

a.button, button {
	display: inline-block;
	padding: .625em 1.25em;
	background: $primary-color;
	color: #fff !important;
	text-decoration: none !important;
    border: none;
    border-radius: 0;
    box-shadow: none;
    text-shadow: none;

	@include transition(all, .1s);

	&:hover {
		background: $secondary-color;
        border: none;
	}
}

//
// Tables
//

table {
	@include table-padded();
	@include table-bordered();
}

//
// Alert
//

.alert {
	display: none;
	padding: 8px 14px 8px 14px;
	margin-bottom: 20px;
	text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
	background-color: #fcf8e3;
	border: 1px solid #fbeed5;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;

	&.show {
		display: block;
	}

	&.success {
	  color: #468847;
	  background-color: #dff0d8;
	  border-color: #d6e9c6;
	}

	&.error {
	  color: #b94a48;
	  background-color: #f2dede;
	  border-color: #eed3d7;
	}
}
