//
// background-clip
//

@mixin background-clip($clip) {
	-webkit-background-clip: $clip;
	-moz-background-clip: $clip;
	-ms-background-clip: $clip;
	-o-background-clip: $clip;
	background-clip: $clip;
}

//
// background-origin
//

@mixin background-origin($origin) {
	-webkit-background-origin: $origin;
	-moz-background-origin: $origin;
	-ms-background-origin: $origin;
	-o-background-origin: $origin;
	background-origin: $origin;
}

//
// background-size
//

@mixin background-size($size) {
	-webkit-background-size: $size;
	-moz-background-size: $size;
	-ms-background-size: $size;
	-o-background-size: $size;
	background-size: $size;
}

//
// border-radius
//

@mixin border-radius($radius) {
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
	-ms-border-radius: $radius;
	-o-border-radius: $radius;
	border-radius: $radius;
}

@mixin border-bottom-radius($radius) {
	-webkit-border-bottom-left-radius: $radius;
	-moz-border-bottom-left-radius: $radius;
	-ms-border-bottom-left-radius: $radius;
	-o-border-bottom-left-radius: $radius;
	border-bottom-left-radius: $radius;
	-webkit-border-bottom-right-radius: $radius;
	-moz-border-bottom-right-radius: $radius;
	-ms-border-bottom-right-radius: $radius;
	-o-border-bottom-right-radius: $radius;
	border-bottom-right-radius: $radius;
}

@mixin border-top-radius($radius) {
	-webkit-border-top-left-radius: $radius;
	-moz-border-top-left-radius: $radius;
	-ms-border-top-left-radius: $radius;
	-o-border-top-left-radius: $radius;
	border-top-left-radius: $radius;
	-webkit-border-top-right-radius: $radius;
	-moz-border-top-right-radius: $radius;
	-ms-border-top-right-radius: $radius;
	-o-border-top-right-radius: $radius;
	border-top-right-radius: $radius;
}

@mixin border-left-radius($radius) {
	-webkit-border-top-left-radius: $radius;
	-moz-border-top-left-radius: $radius;
	-ms-border-top-left-radius: $radius;
	-o-border-top-left-radius: $radius;
	border-top-left-radius: $radius;
	-webkit-border-bottom-left-radius: $radius;
	-moz-border-bottom-left-radius: $radius;
	-ms-border-bottom-left-radius: $radius;
	-o-border-bottom-left-radius: $radius;
	border-bottom-left-radius: $radius;
}

@mixin border-right-radius($radius) {
	-webkit-border-top-right-radius: $radius;
	-moz-border-top-right-radius: $radius;
	-ms-border-top-right-radius: $radius;
	-o-border-top-right-radius: $radius;
	border-top-right-radius: $radius;
	-webkit-border-bottom-right-radius: $radius;
	-moz-border-bottom-right-radius: $radius;
	-ms-border-bottom-right-radius: $radius;
	-o-border-bottom-right-radius: $radius;
	border-bottom-right-radius: $radius;
}

@mixin border-top-left-radius($radius) {
	-webkit-border-top-left-radius: $radius;
	-moz-border-top-left-radius: $radius;
	-ms-border-top-left-radius: $radius;
	-o-border-top-left-radius: $radius;
	border-top-left-radius: $radius;
}

@mixin border-top-right-radius($radius) {
	-webkit-border-top-right-radius: $radius;
	-moz-border-top-right-radius: $radius;
	-ms-border-top-right-radius: $radius;
	-o-border-top-right-radius: $radius;
	border-top-right-radius: $radius;
}

@mixin border-bottom-right-radius($radius) {
	-webkit-border-bottom-right-radius: $radius;
	-moz-border-bottom-right-radius: $radius;
	-ms-border-bottom-right-radius: $radius;
	-o-border-bottom-right-radius: $radius;
	border-bottom-right-radius: $radius;
}

@mixin border-bottom-left-radius($radius) {
	-webkit-border-bottom-left-radius: $radius;
	-moz-border-bottom-left-radius: $radius;
	-ms-border-bottom-left-radius: $radius;
	-o-border-bottom-left-radius: $radius;
	border-bottom-left-radius: $radius;
}

//
// box-shadow
//

@mixin box-shadow($shadow) {
	-webkit-box-shadow: $shadow;
	-moz-box-shadow: $shadow;
	-ms-box-shadow: $shadow;
	-o-box-shadow: $shadow;
	box-shadow: $shadow;
}

//
// box-sizing
//

@mixin box-sizing($sizing) {
	-webkit-box-sizing: $sizing;
	-moz-box-sizing: $sizing;
	-ms-box-sizing: $sizing;
	-o-box-sizing: $sizing;
	box-sizing: $sizing;
}

//
// transition
//

@mixin transition($property: all, $duration: .5s, $easing: linear) {
	-webkit-transition: $property $duration $easing;
	-moz-transition: $property $duration $easing;
	-ms-transition: $property $duration $easing;
	-o-transition: $property $duration $easing;
	transition: $property $duration $easing;
}

//
// linear-gradient
//

@mixin linear-gradient($start: #fff, $stop: #000, $origin: left) {
    background-color: $start;
    background-image: -webkit-linear-gradient($origin, $start, $stop);
    background-image: -moz-linear-gradient($origin, $start, $stop);
    background-image: -o-linear-gradient($origin, $start, $stop);
    background-image: -ms-linear-gradient($origin, $start, $stop);
    background-image: linear-gradient($origin, $start, $stop);
}

//
// user-select
//

@mixin user-select($select) {
	-webkit-user-select: $select;
	-khtml-user-select: $select;
	-moz-user-select: $select;
	-ms-user-select: $select;
	user-select: $select;
}
